/*
 * @Author: your name
 * @Date: 2019-11-30 13:09:28
 * @LastEditTime: 2019-12-03 11:55:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioan\src\api\role.js
 */
import { _axios } from '@/plugins/axios'

export const _tableRole = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/admin/tableRole`,
		method: method,
		data: dataJson
	})
}

export const _tableAllRole = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/role/queryAllRole`,
		method: method,
		data: dataJson
	})
}

export const _addRole = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/admin/addRole`,
		method: method,
		data: dataJson
	})
}

export const _modRole = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/admin/modRole`,
		method: method,
		data: dataJson
	})
}

export const _delRole = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/admin/delRole`,
		method: method,
		data: dataJson
	})
}

/**
 * 通过role_id获取对应权限（未发布）
 * @param {
    "roleId": "2485" # role_id
  }
 * @returns {AxiosPromise}
 */
export const apiGetContactState = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/role/getContactState`,
		method: method,
		data: dataJson
	})
}

export const apiQueryUserList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/user/list`,
		method: method,
		data: dataJson
	})
}

// 申请资料审核用户列表
export const apiApplyUserList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/user/apply/check`,
		method: method,
		data: dataJson
	})
}

// 放款资料审核用户列表
export const apiDisburseUserList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/user/disburse/check`,
		method: method,
		data: dataJson
	})
}

export const apiQueryRoleList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/role/list`,
		method: method,
		data: dataJson
	})
}

// 创建角色
export const apiCreateRole = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/role`,
		method: method,
		data: dataJson
	})
}
// 更新角色
export const apiUpdateRole = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/role/update`,
		method: method,
		data: dataJson
	})
}
// 删除角色
export const apiDeleteRole = (dataJson, method = 'delete') => {
	return _axios({
		url: `/car/role`,
		method: method,
		data: dataJson
	})
}

// 创建用户
export const apiCreateUser = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/user`,
		method: method,
		data: dataJson
	})
}
// 更新用户
export const apiUpdateUser = (dataJson, method = 'patch') => {
	return _axios({
		url: `/car/user`,
		method: method,
		data: dataJson
	})
}

// 分页根据条件查出team列表接口
export const apiQueryTeamName = (dataJson, method = 'get') => {
	return _axios({
		url: `/api/role/team_name`,
		method: method,
		data: dataJson
	})
}

// 获取range配置列表接口
export const apiQueryRangeDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/api/role/queryRangeDetail`,
		method: method,
		data: dataJson
	})
}

// 创建TEAM
export const apiCreateTeam = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/role/createTeam`,
		method: method,
		data: dataJson
	})
}

// 更新权限
export const apiUpdateRange = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/role/updateRange`,
		method: method,
		data: dataJson
	})
}

// 更新，删除TEAM
export const apiUpdateTeam = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/role/updateTeam`,
		method: method,
		data: dataJson
	})
}

export const apiDeleteUser = (dataJson, method = 'delete') => {
	return _axios({
		url: `/car/user`,
		method: method,
		data: dataJson
	})
}

export const apiUserSetRole = (dataJson, method = 'post') => {
	return _axios({
		url: `car/role/userSetRole`,
		method: method,
		data: dataJson
	})
}

export const apiActivateUser = (dataJson, method = 'post') => {
	return _axios({
		url: `/api/role/activateUser`,
		method: method,
		data: dataJson
	})
}

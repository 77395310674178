<template>
	<div>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="btn">
				<a-button type="primary" @click="handleAdd()" v-t.preserve="'table.EmployeeManagement.Branchman.addBranch'"></a-button>
			</template>
			<template slot="action" slot-scope="{ columnData }">
				<a @click="handleEdit(columnData)">{{ $t('page.edit') }}</a>
				<a-divider type="vertical" />
				<a @click="handleDelete(columnData)">{{ $t('page.delete') }}</a>
			</template>
		</search-list-table>
		<a-modal
			v-drag-modal
			v-model="userModal.show"
			:destroyOnClose="true"
			:title="$t(`table.EmployeeManagement.Branchman.add${userModal.type.charAt(0).toUpperCase() + userModal.type.slice(1)}`)"
			width="80%"
			ok-text="ok"
			cancel-text="cancel"
			@ok="submitFrom"
		>
			<a-form-model layout="inline">
				<a-form-model-item :label="$t('table.Permission.User.username')">
					<a-input v-model="userModal.userId"></a-input>
				</a-form-model-item>
				<a-form-model-item>
					<a-button type="primary" @click="SearchUserList" v-t.preserve="'operate.search'"></a-button>
				</a-form-model-item>
			</a-form-model>
			<a-table
				:columns="columns2"
				size="small"
				rowKey="id"
				:dataSource="userList"
				:pagination="false"
				:row-selection="{
					fixed: true,
					columnWidth: 50,
					onChange: (k, r) => {
						;(this.selectedRowKeys = k), (this.selectedRows = r)
					},
					selectedRowKeys: selectedRowKeys
				}"
				bordered
			></a-table>
		</a-modal>
		<a-modal
			v-drag-modal
			v-model="createModal.show"
			:title="$t(createModal.type === 'add' ? 'table.EmployeeManagement.Branchman.addBranch' : 'table.EmployeeManagement.Branchman.editBranch')"
			:destroyOnClose="true"
			:confirm-loading="createModal.loading"
			width="800px"
			@ok="handleCreateModalOk"
		>
			<div class="createModal">
				<a-form-model
					:ref="createModal.form"
					:rules="createModal.rules"
					:model="createModal.data"
					:labelCol="createModal.labelCol"
					:wrapperCol="createModal.wrapperCol"
				>
					<a-form-model-item :label="$t('table.EmployeeManagement.Branchman.city')" prop="cityCode">
						<a-select v-model="createModal.data.cityCode">
							<a-select-option value="">
								{{ $t('table.all') }}
							</a-select-option>
							<a-select-option :value="item.id" v-for="(item, idx) in cityList" :key="idx">{{ isLang ? item.id : item.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.EmployeeManagement.Branchman.branchOffice')" prop="storeName">
						<a-input v-model="createModal.data.storeName"></a-input>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.EmployeeManagement.Branchman.branchID')" prop="storeNo">
						<a-input v-model="createModal.data.storeNo"></a-input>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.EmployeeManagement.Branchman.branchAddress')" prop="address">
						<a-input type="textarea" v-model="createModal.data.address"></a-input>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.EmployeeManagement.Branchman.addSales')" prop="salesmen">
						<a-tag v-for="(item, idx) in createModal.data.salesmen" :key="item.salesmanId" closable @close="delSalesmen(idx)">{{
							String(item.salesmanName)
						}}</a-tag>
						<a-icon type="plus-square" class="add-sales" @click="SearchUserList('sales')" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.EmployeeManagement.Branchman.addStaff')" prop="staffsmen">
						<a-tag v-for="(item, idx) in createModal.data.staffsmen" :key="item.userId" closable @close="delStaffsmen(idx)">{{
							String(item.username)
						}}</a-tag>
						<a-icon type="plus-square" class="add-sales" @click="SearchUserList('staff')" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.EmployeeManagement.Branchman.addCollectors')" prop="collectors">
						<a-tag v-for="(item, idx) in createModal.data.collectors" :key="item.userId" closable @close="delCollectors(idx)">{{
							String(item.username)
						}}</a-tag>
						<a-icon type="plus-square" class="add-sales" @click="SearchUserList('collectors')" />
					</a-form-model-item>
				</a-form-model>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { apiQueryUserList } from '@/api/role'
import { apiCityList, apiSetBranch, apiBranchManList, apiBranchDel } from '@/api/branch'

export default {
	data() {
		return {
			cityList: [],
			createModal: {
				loading: false,
				show: false,
				type: 'add',
				form: 'createModalForm',
				labelCol: { span: 4 },
				wrapperCol: { span: 12 },
				data: {
					storeName: '',
					storeNo: '',
					cityCode: '',
					salesmen: []
				},
				rules: {
					storeName: [
						{
							required: true,
							whitespace: true
						}
					],
					cityCode: [
						{
							required: true
						}
					]
				}
			},
			userModal: {
				userId: '',
				type: '',
				show: false
			},
			columns2: [
				{
					// username
					title: this.$t('table.Permission.User.username'),
					dataIndex: 'username',
					align: 'center'
				},
				{
					// displayName
					title: this.$t('table.Permission.User.displayName'),
					dataIndex: 'displayName',
					align: 'center'
				},
				{
					// mobile
					title: this.$t('table.Permission.User.mobile'),
					dataIndex: 'mobile',
					align: 'center'
				}
			],
			userList: [],
			selectedRowKeys: [],
			selectedRows: []
		}
	},
	computed: {
		...mapGetters(['setting']),
		isLang() {
			return this.setting.curLanguage.locale === 'en'
		},
		formSearchList() {
			return [
				{
					label: 'table.EmployeeManagement.Branchman.city',
					type: 'select',
					validateField: ['cityCode'],
					selectOption: this.cityList,
					dataPreType: {
						value: 'id',
						text: this.isLang ? 'id' : 'name'
					}
				},
				{
					label: 'table.EmployeeManagement.Branchman.branchOffice',
					type: 'input',
					validateField: ['storeName']
				},
				{
					label: 'table.EmployeeManagement.Branchman.branchID',
					type: 'input',
					validateField: ['storeNo']
				}
			]
		},
		columns() {
			return [
				{
					// 城市
					title: this.$t('table.EmployeeManagement.Branchman.city'),
					dataIndex: 'cityCode',
					align: 'center',
					width: 100,
					customRender: (v, o) => {
						return this.isLang ? o.cityCode : o.cityName
					}
				},
				{
					// 门店名称
					title: this.$t('table.EmployeeManagement.Branchman.branchOffice'),
					dataIndex: 'storeName',
					align: 'center'
				},
				{
					// 门店地址
					title: this.$t('table.EmployeeManagement.Branchman.branchAddress'),
					dataIndex: 'address',
					align: 'center'
				},
				{
					// 门店编号
					title: this.$t('table.EmployeeManagement.Branchman.branchID'),
					dataIndex: 'storeNo',
					align: 'center'
				},
				{
					// 门店员工
					title: this.$t('table.EmployeeManagement.Branchman.branchSales'),
					dataIndex: 'salesmen',
					align: 'center',
					width: 200,
					customRender: (v, o) => {
						let salesmanName = []
						if (v) {
							v.forEach((element) => {
								salesmanName.push(String(element.salesmanName))
							})
							return salesmanName.join(',')
						}
					}
				},
				{
					// 门店销售
					title: this.$t('table.EmployeeManagement.Branchman.branchStaffs'),
					dataIndex: 'staffs',
					align: 'center',
					width: 200,
					customRender: (v, o) => {
						let staffsName = []
						if (v) {
							v.forEach((element) => {
								staffsName.push(String(element.username))
							})
							return staffsName.join(',')
						}
					}
				},
				{
					// 催收员
					title: this.$t('table.EmployeeManagement.Branchman.branchCollector'),
					dataIndex: 'collectors',
					align: 'center',
					width: 200,
					customRender: (v, o) => {
						let staffsName = []
						if (v) {
							v.forEach((element) => {
								staffsName.push(String(element.username))
							})
							return staffsName.join(',')
						}
					}
				},
				{
					// 创建时间
					title: this.$t('table.EmployeeManagement.Salesman.createDate'),
					dataIndex: 'createTime',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('operate.operate'),
					scopedSlots: { customRender: 'action' },
					width: 150,
					align: 'center'
				}
			]
		}
	},
	mounted() {
		this.initCity()
	},
	methods: {
		initDataSource(initParams) {
			return apiBranchManList(initParams)
		},
		async initCity() {
			this.cityList = await apiCityList()
		},
		resetCreateModal() {
			this.createModal.data = {
				storeName: '',
				storeNo: '',
				cityCode: '',
				address: '',
				salesmen: [],
				staffsmen: [],
				collectors: [],
				id: null
			}
		},
		showCreateModal(type, data) {
			this.resetCreateModal()
			this.createModal.type = type
			if (type === 'edit') {
				this.createModal.data = {
					storeName: data.storeName,
					storeNo: data.storeNo,
					cityCode: data.cityCode,
					address: data.address,
					salesmen: [...data.salesmen],
					staffsmen: [...data.staffs],
					collectors: [...data.collectors],
					id: data.id
				}
			}
			this.createModal.show = true
		},
		delSalesmen(idx) {
			this.createModal.data.salesmen.splice(idx, 1)
		},
		delStaffsmen(idx) {
			this.createModal.data.staffsmen.splice(idx, 1)
		},
		delCollectors(idx) {
			this.createModal.data.collectors.splice(idx, 1)
		},
		async handleDelete(obj) {
			this.$confirm({
				content: this.$t('page.confirm'),
				onOk: async () => {
					try {
						let res = await apiBranchDel(obj.id)
						this.$refs.searchListTable.initTable(1)
					} catch (e) {
						this.$error({
							content: e.message
						})
					}
				}
			})
		},
		async handleAdd(obj) {
			this.showCreateModal('add')
		},
		async handleEdit(obj) {
			this.showCreateModal('edit', obj)
		},
		async handleCreateModalOk() {
			await this.$refs.createModalForm.validate()
			let params = {
				storeName: this.createModal.data.storeName,
				storeNo: this.createModal.data.storeNo,
				cityCode: this.createModal.data.cityCode,
				address: this.createModal.data.address,
				salesmen: this.createModal.data.salesmen,
				staffs: this.createModal.data.staffsmen,
				collectors: this.createModal.data.collectors,
				id: this.createModal.data.id
			}
			try {
				this.createModal.loading = true
				let res = await apiSetBranch(params)
				this.$refs.searchListTable.initTable(1)
			} catch (e) {
				this.$error({
					content: e.message
				})
			}
			this.createModal.loading = false
			this.createModal.show = false
		},
		async SearchUserList(type) {
			const data = await apiQueryUserList({
				username: this.userModal.userId,
				enabled: 0,
				pageNum: 0,
				pageSize: 20000
			})
			this.userList = data.pageItems
			switch (type) {
				case 'sales':
					this.selectedRowKeys = this.createModal.data.salesmen.map((value) => value.salesmanId)
					break
				case 'staff':
					this.selectedRowKeys = this.createModal.data.staffsmen.map((value) => value.userId)
					break
				case 'collectors':
					this.selectedRowKeys = this.createModal.data.collectors.map((value) => value.userId)
					break
				default:
					break
			}
			// this.selectedRowKeys =
			// 	type === 'sales'
			// 		? this.createModal.data.salesmen.map((value) => value.salesmanId)
			// 		: this.createModal.data.staffsmen.map((value) => value.userId)
			this.userModal.type = type
			this.userModal.show = true
		},
		// 提交表单
		submitFrom() {
			if (this.selectedRows.length > 0) {
				if (this.createModal.data.salesmen === null) {
					this.createModal.data.salesmen = []
				}
				if (this.createModal.data.staffsmen === null) {
					this.createModal.data.staffsmen = []
				}
				if (this.createModal.data.collectors === null) {
					this.createModal.data.collectors = []
				}
				if (this.userModal.type === 'sales') {
					this.createModal.data.salesmen = []
					this.selectedRows.map((item) => {
						this.createModal.data.salesmen.push({
							salesmanId: item.id,
							salesmanName: item.displayName
						})
					})
				} else if (this.userModal.type === 'staff') {
					this.createModal.data.staffsmen = []
					this.selectedRows.map((item) => {
						this.createModal.data.staffsmen.push({
							userId: item.id,
							username: item.displayName
						})
					})
				} else if (this.userModal.type === 'collectors') {
					this.createModal.data.collectors = []
					this.selectedRows.map((item) => {
						this.createModal.data.collectors.push({
							userId: item.id,
							username: item.displayName
						})
					})
				}
				// this.userModal.type === 'sales' ? (this.createModal.data.salesmen = []) : (this.createModal.data.staffsmen = [])
				// this.selectedRows.map((item) => {
				// 	this.userModal.type === 'sales'
				// 		? this.createModal.data.salesmen.push({
				// 				salesmanId: item.id,
				// 				salesmanName: item.displayName
				// 		  })
				// 		: this.createModal.data.staffsmen.push({
				// 				userId: item.id,
				// 				username: item.displayName
				// 		  })
				// })
				this.userModal.show = false
				this.userModal.userId = ''
			}
		}
	}
}
</script>

<style scoped lang="less">
.title button {
	float: right;
	margin-right: 10px;
}
.ant-select {
	width: 200px;
}
.add-sales {
	font-size: 24px;
	vertical-align: middle;
	color: #1890ff;
}
.ant-tag {
	padding: 5px 10px;
}
</style>
